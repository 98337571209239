import React from 'react'
import Card from './Card';
import Cont from './Cont';
import Edq from './Edq';
import Img from "./Img"
import Slider from './Slider';
import Video from './Video';

const Home = () => {
  return (
    <div>
      <Cont/>
      <Slider/>
      <Edq/>
      <Video/>
      <Card/>
      <Img/>
    </div>
  )
}

export default Home;