import React from 'react'

const Cont = () => {
    return (
       <div className='wp-block-buttons align-content-center' style={{height:"30px",padding:0,margin:10,display:"flex",justifyContent:"center",justifyContent:"space-around"}}>
        <div className="card" style={{backgroundColor:"black"}}>
            <a className="wp-block-button__link" href="tel:91 9414323981" style={{borderRadius:"11px"}}>
                <img style={{width:"20px"}} draggable="false"  className="emoji" alt="📞" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f4de.svg"/>91 9414323981</a>
        </div>
        <div className="card" style={{backgroundColor:"black"}}>
            <a className="wp-block-button__link has-background has-vivid-green-cyan-background-color" href="https://wa.me/qr/LSB3MUFIBBZ2F1" style={{borderRadius:"11px"}}>
                <img style={{width:"20px"}}  draggable="false" className="emoji" alt="💬" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f4ac.svg"/> WhatsApp</a></div>
        </div>
    )
}

export default Cont;
