import React from 'react'

const About = () => {
    return (
        <div className='container' >
            <div className='box bg-info' style={{margin:10,borderRadius:20}}>
                 <div className='row'>
                     <div className='col-md-6'>
                           <p className='mb-5' style={{margin:20,fontSize:23}}>
                           हर दिन हम इस तथ्य के प्रति जागते हैं कि 250 मिलियन से अधिक लोग भारतीय जीवन बीमा पॉलिसी नामक हमारे परिवार का हिस्सा हैं। <br/>

हम जो जिम्मेदारी निभाते हैं उसके परिमाण से हम विनम्र हैं और यह महसूस करते हैं कि हमारे साथ जुड़े जीवन वास्तव में बहुत मूल्यवान हैं। <br/>

हालांकि यह यात्रा छह दशक पहले शुरू हुई थी, हम अभी भी इस तथ्य के प्रति सचेत हैं कि, जबकि बीमा हमारे लिए एक व्यवसाय हो सकता है, पिछले 65 वर्षों से हर दिन लाखों लोगों के जीवन का हिस्सा बनना एक प्रक्रिया रही है जिसे ट्रस्ट कहा जाता है। <br/>

<span style={{color:"black"}}>भरोसे की सच्ची गाथा।</span>
                           </p>
                     </div>
                     <div className='col-md-6'>
                        <img style={{width:"100%",height:"420px"}} alt='' src='/img/bulletin-board-3127287__340.webp'/>
                     </div>
                 </div>
            </div>
            <div id='Image'>
                <h3>LIC IMAGES</h3>
                <div className='row'>

                    <div className='col-md-4'>
                        <img style={{width:"100%" ,marginTop:20}} alt=' ' src='/img/lic image_page-0001.jpg'/>
                    </div>
                    <div className='col-md-4'>
                        <img style={{width:"100%" ,marginTop:20}} alt=' ' src='/img/lic image_page-0002.jpg'/>
                    </div>
                    <div className='col-md-4'>
                        <img style={{width:"100%" ,marginTop:20}} alt=' ' src='/img/lic image_page-0003.jpg'/>
                    </div>
                    <div className='col-md-4'>
                        <img style={{width:"100%" ,marginTop:20}} alt=' ' src='/img/lic image_page-0004.jpg'/>
                    </div>
                    <div className='col-md-4'>
                        <img style={{width:"100%" ,marginTop:20}} alt=' ' src='/img/lic image_page-0005.jpg'/>
                    </div>
                    <div className='col-md-4'>
                        <img style={{width:"100%" ,marginTop:20}} alt=' ' src='/img/lic image_page-0006.jpg'/>
                    </div>
                    <div className='col-md-4'>
                        <img style={{width:"100%" ,marginTop:20}} alt=' ' src='/img/lic image_page-0007.jpg'/>
                    </div>
                    <div className='col-md-4'>
                        <img style={{width:"100%" ,marginTop:20}} alt=' ' src='/img/lic image_page-0008.jpg'/>
                    </div>

                </div>
                </div>
                <div>
                    <h3>Death Claim  </h3>
                    <div className='row'>
                        <div className='col-md-4'>
                            <img style={{width:"100%" ,marginTop:20}} alt='' src='/img/death claim_page-0001.jpg'/>
                        </div>
                        <div className='col-md-4'>
                            <img style={{width:"100%" ,marginTop:20}} alt='' src=' /img/death claim_page-0001.jpg'/>
                        </div>
                        <div className='col-md-4'>
                            <img style={{width:"100%" ,marginTop:20}} alt='' src=' /img/death claim_page-0002.jpg'/>
                        </div>
                        <div className='col-md-4'>
                            <img style={{width:"100%" ,marginTop:20}} alt='' src=' /img/death claim_page-0003.jpg'/>
                        </div>
                        <div className='col-md-4'>
                            <img style={{width:"100%" ,marginTop:20}} alt='' src=' /img/death claim_page-0004.jpg'/>
                        </div>
                    </div>
                
            </div>
        </div>
    )
}

export default About
