import React from 'react'
import { Link } from 'react-router-dom';

const Img = () => {
  return (
      <>
     
    <div className='box bg-light' style={{margin:20,borderRadius:10}}>
        <div className='row'>
            <div className='col-md-4'>
                <div className='box bg-light' style={{borderRadius:20}}>
                     <img style={{width:"100%"}} alt='#' src='/img/WhatsApp Image 2022-05-22 at 4.01.13 PM.jpeg'/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='box bg-light' style={{borderRadius:20}}>
                     <img style={{width:"100%"}} alt='#' src='/img/WhatsApp Image 2022-05-22 at 4.04.47 PM.jpeg'/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='box bg-light' style={{borderRadius:20}}>
                     <img style={{width:"100%"}} alt='#' src='/img/WhatsApp Image 2022-05-22 at 4.27.56 PM.jpeg'/>
                </div>
            </div>
            {/* <div className='col-md-4'>
                <div className='box bg-light' style={{borderRadius:20}}>
                     <img style={{width:"100%"}} alt='#' src='/img/WhatsApp Image 2022-05-22 at 4.27.56 PM.jpeg'/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='box bg-light' style={{borderRadius:20}}>
                     <img style={{width:"100%"}} alt='#' src='/img/WhatsApp Image 2022-05-22 at 4.27.56 PM.jpeg'/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='box bg-light' style={{borderRadius:20}}>
                     <img style={{width:"100%"}} alt='#' src='/img/WhatsApp Image 2022-05-22 at 4.27.56 PM.jpeg'/>
                </div>
            </div> */}
            <div style={{textAlign:"center" , margin:20}}><button style={{ borderRadius:10}}><Link className="nav-link" to="Image">और देखें</Link></button></div>
        </div>
    </div>
    <div>
        <div className='box bg-light' style={{borderRadius:10,margin:20}}>
            <div>
                <h1>आवेदन कैसे करें</h1>
                <ol style={{fontSize:25,margin:20}}>
                    <li>10वीं की मार्कशीट</li>
                    <li>आधार कार्ड या वोटर आई.डी.कार्ड </li>
                    <li>4 पासपोर्ट साइज फोटो</li>
                    <li>पैन कार्ड</li>
                    <li>बैंक खाता</li>
                </ol>
            </div>
        </div>
        
        <div className='' >
            <div className='card'>
                <form>
                    <div style={{margin:10}}>
                    <label >First name</label><br/>
                    <input style={{width:"100%",borderRadius:10}} type="text" required></input><br/>
                    <label>Last name</label><br/>
                    <input style={{width:"100%",borderRadius:10}}type="text" required></input><br/>
                    <label>Contact No</label><br/>
                    <input style={{width:"100%",borderRadius:10}} type="number" required></input><br/>
                    <label>Age</label><br/>
                    <input style={{width:"100%",borderRadius:10}} type="number" required></input><br/>
                    </div>
         
                    <div style={{margin:10}}>
                    <label>10वीं की मार्कशीट अपलोड करें।</label>
                    <input type="file" id="img" name="img" accept="image/*" required/>
                    </div>
                    <div  style={{margin:10}}>
                    <label>आधार कार्ड या वोटर आई.डी.कार्ड अपलोड करें।</label>
                    <input type="file" id="img" name="img" accept="image/*" required/>
                    </div>
                    <div  style={{margin:10}}>
                    <label>पासपोर्ट साइज फोटो अपलोड करें।</label>
                    <input type="file" id="img" name="img" accept="image/*" required/>
                    </div>
                    <div style={{margin:10}}>
                    <label>10वीं की मार्कशीट अपलोड करें।</label>
                    <input type="file" id="img" name="img" accept="image/*" required/>
                    </div>
                    <div style={{margin:10}}>
                    <label>10वीं की मार्कशीट अपलोड करें।</label>
                    <input type="file" id="img" name="img" accept="image/*" required/>
                    </div>
                    <div style={{textAlign:"center",display:"block"}}>
                    <button style={{marginBottom:20,marginTop:10,}}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </>
  )
}

export default Img;