import React from 'react'

const Edq = () => {
  return (
    <div>
        <div>
            <marquee><h1>देश की सबसे बड़ी सरकारी जीवन बीमा कंपनी में एजेंट बीमा कंपनी LIC से जुड़ने का सुनहरा अवसर</h1></marquee>
        </div>
        <div className='box bg-light' style={{margin:20,borderRadius:10}}>
            <h3 style={{fontSize:25,margin:20}}>पात्रता एवं शैक्षणिक योग्यताएं</h3>
            <p style={{fontSize:20,margin:20}}>(1) 10 पास</p>
            <p style={{fontSize:20,margin:20}}>(2) आयु: महिला एवं पुरुष जिनकी आयु 18 से 65 वर्ष तक हो</p>
        </div>
    </div>
  )
}

export default Edq;