import React from 'react'

const SocialImage = (props) => {
  return (
    <div>
        <div className='container'>
            <div className='row'>
             
                <div className='col-md-4'>
                <div className='card bg-gradient'>
                   <img style={{Width:"90%",height:"300px" ,marginTop:10,marginHorizontal:10}} alt='' src={props.Image1}/>
                </div>
                </div>
                <div className='col-md-4'>
                <div className='card bg-gradient'>
                   <img style={{Width:"90%",height:"300px" ,marginTop:10,marginHorizontal:10}} alt='' src={props.Image2}/>
                </div>
                </div>
                <div className='col-md-4'>
                <div className='card bg-gradient'>
                   <img style={{Width:"90%",height:"300px" ,marginTop:10,marginHorizontal:10}} alt='' src={props.Image3}/>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SocialImage;