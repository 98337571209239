import React from 'react'

const Contact = () => {
  return (
    <div>
      <div className='box bg-light' style={{margin:40,borderRadius:20}}>
        <form style={{margin:40}}>
          <label >First name</label><br/>
          <input style={{width:"100%",borderRadius:10}} type="text" required></input><br/>
          <label>Last name</label><br/>
          <input style={{width:"100%",borderRadius:10}}type="text" required></input><br/>
          <label>Contact No</label><br/>
          <input style={{width:"100%",borderRadius:10}} type="number" required></input><br/>
          <label>Age</label><br/>
          <input style={{width:"100%",borderRadius:10}} type="number" required></input><br/>
          <label>Query</label><br/>
          <textarea style={{width:"100%",borderRadius:10}} type="text"></textarea><br/>
          <button style={{marginBottom:20,marginTop:10}}>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default Contact;    