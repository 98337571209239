import React from 'react'

const Any = (props) => {
   const Margins = {
    margin:30
   }
    return (
        <div style={Margins}>
            <div className='row'>
        <div className='col-md-4' style={{marginTop:10}}>
            <div className='box bg-light'  style={{height:"200px" , borderRadius:10}}>
                <img style={{width:"30%",marginLeft:100,borderRadius:50,textAlign:"center"}} src={props.source1} alt=''/>
                <h1 style={{marginTop:10}}>{props.hadding1}</h1>
            </div>
        </div>
        <div className='col-md-4' style={{marginTop:10}}>
            <div className='box bg-light' style={{height:"200px" , borderRadius:10}}>
                <img style={{width:"20%",marginLeft:100,borderRadius:50,textAlign:"center"}} src={props.source2} alt=''/>
                <h1 style={{marginTop:10}}>{props.hadding2}</h1>
            </div>
        </div>
        <div className='col-md-4' style={{marginTop:10}}>
            <div className='box bg-light' style={{height:"200px" , borderRadius:10}}>
                <img style={{width:"30%",marginLeft:100,borderRadius:50,textAlign:"center"}} src={props.source3} alt=''/>
                <h1 style={{marginTop:10}}>{props.hadding3}</h1>
            </div>
        </div>
        </div>
        </div>
    )
}

export default Any;


