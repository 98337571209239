import React, { useState } from "react";
import "./NavBar.css";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  return (
    <>
      <nav className="main-nav">
        {/* 1st logo part  */}
        <div className="logo">
        <img style={{width:"28%"}} alt="" src="/img/Logo.jpg"/>
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }>
          <ul>
            <li>
            <NavLink className="nav-link active" aria-current="page" to="/">Home</NavLink>
            </li>
            <li>
            <NavLink className="nav-link active" aria-current="page" to="About">About</NavLink>
            </li>
            <li>
            <NavLink className="nav-link" to="Achievement">Achievements</NavLink>
            </li>
            <li>
             <NavLink className="nav-link" to="Social">Social Working</NavLink>
            </li>
            <li>
            <NavLink className="nav-link" to="Contact" >Contact</NavLink>
            </li>
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">
          <ul className="social-media-desktop">
            <li>
              <a
                href="https://www.facebook.com/jagdishprasad.saini.3785"
                target="_jagdish">
                <FaFacebookSquare className="facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://youtube.com/channel/UCdOGq5vKsx9chMHnVCa6MiQ"
                target="_jagdish">
                <FaInstagramSquare className="instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://youtube.com/channel/UCdOGq5vKsx9chMHnVCa6MiQ"
                target="_jagdish">
                <FaYoutubeSquare className="youtube" />
              </a>
            </li>
          </ul>

          {/* hamburget menu start  */}
          <div className="hamburger-menu">
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>

      {/* hero section  */}
      {/* <section className="hero-section">
        <p>Welcome to </p>
        <h1>Thapa Technical</h1>
      </section> */}
    </>
  );
};

export default Navbar;
