import React from 'react';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
//import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import { Route,Switch } from 'react-router-dom';
import Home from './Pages/Home';
import Menu from './Pages/Menu';
import Footer from './Pages/Footer';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Achievements from './Pages/Achievements';
import Social from "./Pages/Social"
import NavBar from './Pages/NavBar';
const App = () => {
  return (
    <div style={{backgroundColor:"pink"}}>
      <NavBar/>
        {/* <Menu/> */}
        <Switch>
            <Route exact path='/' component ={Home}></Route>
            <Route exact path='/About' component ={About}></Route>
            <Route exact path='/Contact' component ={Contact}></Route>
            <Route exact path='/Achievement' component ={Achievements}></Route>
            <Route exact path="/Social" component = {Social}></Route>
            
        </Switch>
        <Footer/>
    </div>
  )
};

export default App;