import React from 'react'
import Images from './Images';

const Achievements = () => {
  return (
    <div className='container'>
        <div>
      <Images Image = "/img/2064eb39-1a88-4bea-aec5-914dd2331e55.jpg" Image1="/img/36e67aa9-266e-4cd3-bcb2-b98443d5b94d.jpg" Image2="/img/3b659c75-a8c1-43e0-a9c9-4a18abdbc54f.jpg"/>
      </div>
      <div>
      <Images Image = "/img/WhatsApp Image 2022-06-15 at 1.03.34 PM.jpeg" Image1="/img/WhatsApp Image 2022-06-15 at 1.03.36 PM (1).jpeg" Image2="/img/WhatsApp Image 2022-06-15 at 1.03.37 PM.jpeg"/>
      </div>
      <div>
      <Images Image = "/img/WhatsApp Image 2022-06-15 at 1.03.36 PM (2).jpeg" Image1="/img/WhatsApp Image 2022-06-15 at 1.03.36 PM.jpeg" Image2="/img/WhatsApp Image 2022-06-15 at 1.07.40 PM.jpeg"/>
      </div>
      <div>
      <Images Image = "/img/WhatsApp Image 2022-06-15 at 1.08.18 PM.jpeg" Image1="/img/WhatsApp Image 2022-06-15 at 1.08.54 PM.jpeg" Image2="/img/WhatsApp Image 2022-06-15 at 1.09.54 PM.jpeg"/>
      </div>
    
    
     
      <div>
      <Images Image = "/img/WhatsApp Image 2022-06-15 at 1.10.53 PM (1).jpeg" Image1="/img/WhatsApp Image 2022-06-15 at 1.16.36 PM.jpeg" Image2="/img/WhatsApp Image 2022-06-15 at 1.16.39 PM.jpeg"/>
      </div>
      <div>
      <Images Image = "/img/WhatsApp Image 2022-06-15 at 1.16.40 PM (1).jpeg" Image1="/img/WhatsApp Image 2022-06-15 at 1.16.40 PM (2).jpeg" Image2="/img/WhatsApp Image 2022-06-15 at 1.16.40 PM.jpeg"/>
      </div>
     </div>
  )
}

export default Achievements;