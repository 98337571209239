import React from 'react'

const Images = (props) => {
  return (
    <div>
        <div className='row'>
            <div className='col-md-4'>
               <div className='box bg-light'>
                   <img style={{width:"100%",height:"400px"}} alt='' src={props.Image}/>
               </div> 
            </div>
            <div className='col-md-4'>
               <div className='box bg-light'>
                   <img style={{width:"100%",height:"400px"}} alt='' src={props.Image1}/>
               </div> 
            </div>
            <div className='col-md-4'>
               <div className='box bg-light'>
                   <img style={{width:"100%",height:"400px"}} alt='' src={props.Image2}/>
               </div> 
            </div>
            
        </div>
    </div>
  )
}

export default Images;