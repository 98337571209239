import React from 'react';
import Any from './Any';
import SocialImage from './SocialImage';

const Social = () => {
    return (
        <>
        <div>
         
           <Any source1="/img/poor-2754335__340.jpg" source2="/img/nurse-2683782_960_720.png" source3="/img/family-2073604__340.webp" hadding1="चाईल्ड डेवलेमेंट" hadding2="हॉस्पिटल की सुविधाओ में विकास" hadding3="साइकोलॉजिकल क्षेत्र में विकास"/>
           <Any source1="/img/poor-2754335__340.jpg" source2="/img/nurse-2683782_960_720.png" hadding1="डिजास्टर मैनेजमेंट" hadding2="स्कूल में शिक्षा क्षेत्र में विकास" hadding3="डिसिशन & मैनेजमेंट के क्षेत्र में विकास"/>
           <Any source1="/img/poor-2754335__340.jpg" source2="/img/nurse-2683782_960_720.png" hadding1="अंतररास्ट्रीय सोशल वर्क" hadding2="किसी संस्था के लिए सामाजिक विकास" />
           
        </div>
        <div style={{backgroundColor:"gray"}}>
        <h1>Same Beautifully Moments</h1>
            <div>
            <SocialImage Image1="/img/social working_page-0001.jpg" Image2="/img/social working_page-0002.jpg" Image3="/img/social working_page-0003.jpg" />
            </div>
            <div>
            <SocialImage Image1="/img/social working_page-0004.jpg" Image2="/img/social working_page-0005.jpg" Image3="/img/social working_page-0006.jpg" />
            </div>
            <div>
            <SocialImage Image1="/img/social working_page-0007.jpg" Image2="/img/social working_page-0008.jpg" Image3="/img/social working_page-0009.jpg" />
            </div>
            <SocialImage Image1="/img/social working_page-0010.jpg" Image2="/img/social working_page-0011.jpg" Image3="/img/social working_page-0012.jpg" />
            <div>
            <SocialImage Image1="/img/social working_page-0013.jpg" Image2="/img/social working_page-0014.jpg" Image3="/img/social working_page-0015.jpg" />
            </div>
        </div>
        </>
    )
}

export default Social
