import React from 'react'

const Footer = () => {
  return (
    <div>
        <div style={{backgroundColor:"lightblue",textAlign:"center"}}>
            <h1>जगदीश सैनी (बागड़ी)</h1>
            <h3>अधिक जानकारी के लिए संपर्क करें :-</h3>
            <p style={{fontSize:20}}>(मुख्य जीवन बीमा सलाहकार)</p>
            <p style={{fontSize:20}}>मो.9414323981,9929522462</p>
            <p style={{fontSize:20}}>कार्यालय:-कोटा वाली बिल्डिंग,त्रिपोलिया बाजार,जयपुर</p>
        </div>
    </div>
  )
}

export default Footer;