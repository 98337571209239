import React from 'react'
import { NavLink } from 'react-router-dom'

const Card = () => {
  return (
    <div>
        <div className='box bg-light' style={{margin:20,borderRadius:10}}>
             <div className='row' >
                <div className='col-md-4'>
                    <img style={{width:"100%"}} alt='' src='/img/download.jpg'/>
                </div>
                <div className='col-md-4'>
                     <h1 style={{margin:10}}>देश की सबसे बड़ी सरकारी आधिपत्य जीवन बीमा कंपनी में एजेंट भर्ती से जुड़ने का सुनहरा अवसर।</h1>
                </div>
                <div className='col-md-4'>
                   <button  style={{borderRadius:10,margin:100,textAlign:"center",fontSize:25}}><NavLink  className="nav-link" to="Contact">संपर्क करें</NavLink></button>
                </div>
             </div>
        </div>
    </div>
  )
}

export default Card