import React from 'react'

const Video = () => {
  return (
    <div className='Container'style={{margin:25}}>
        <div className='row'>
            <div className="col-md-4">
            <video width="320" height="240" color='black' controls>
                <source src="/Video/VID-20220605-WA0017.mp4" type="video/mp4"/>
               
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='col-md-4'>
            <video width="320" height="240" color='black' controls>
                <source src="/Video/VID-20220608-WA0016.mp4" type="video/mp4"/>
               
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='col-md-4'>
            <video width="320" height="240" color='black' controls>
                <source src="/Video/VID-20220608-WA0018.mp4" type="video/mp4"/>
               
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='col-md-4'>
            <video width="320" height="240" color='black' controls>
                <source src="/Video/VID-20220802-WA0011.mp4" type="video/mp4"/>
               
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='col-md-4'>
            <video width="320" height="240" color='black' controls>
                <source src="/Video/VID-20220802-WA0012.mp4" type="video/mp4"/>
               
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='col-md-4'>
            <video width="320" height="240" color='black' controls>
                <source src="/Video/VID-20220802-WA0019.mp4" type="video/mp4"/>
               
                Your browser does not support the video tag.
              </video>
            </div>
            
        </div>
    </div>
  )
};

export default Video;