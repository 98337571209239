import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
const Slider = () => {
  return (
    <div className='container'>
        <div>
        <marquee  width="100%" direction="left" height="60px">
            <p style={{fontSize:30}}>एलआईसी एजेंट भर्ती के तहत ₹6000 मासिक और लाखों रुपए कमीशन के रूप में कमाने का सुनहरा मौका  </p>
           
            
            </marquee>
        </div>
        <Carousel>
            <Carousel.Item>
                <img style={{}}
                src='/img/WhatsApp Image 2022-06-15 at 1.10.53 PM.jpeg'
                alt=''
                className='d-block w-100'/>
            </Carousel.Item>
            <Carousel.Item>
                <img
                style={{}}
                src='/img/WhatsApp Image 2022-05-22 at 4.27.56 PM.jpeg'
                alt=''
                className='d-block w-100'/>
            </Carousel.Item>
            <Carousel.Item>
                <img
                style={{width:"80%"}}
                src='/img/WhatsApp Image 2022-05-22 at 3.53.46 PM.jpeg'
                alt=''
                className='d-block w-100'/>
            </Carousel.Item>
        </Carousel>
        <div style={{textAlign:"right",color:"Highlight"}}>
          <h6>नियम व शर्तें लागू</h6>
            </div>
        <div style={{margin:20}}>
            <h1><strong>सुनेहरा अवसर</strong> जीवन बीमा एजेंट भर्ती अभियान</h1>
            <div className='box bg-light' style={{borderRadius:10}}>
            <ul style={{fontSize:25}}>
                <li>आप स्वयं अपने बॉस होंगे</li>
                <li>आप स्वयं अपने कार्य के समय का निर्धारण करेंगे</li>
                <li>आप स्वयं अपनी आय का निर्धरन करेंगे</li>
                {/* <li>आप आज आम का पेड़ लगाएंगे और वर्षो तक फल खाएंगे</li> */}
                <li>आप बिना दुकान, स्थान ,राशि के व्यवसाय का शुभारम्भ कर सकते हैं</li>
                <li>काम करने की सीमा नहीं है</li>
            </ul>
          
            </div>
            <div style={{textAlign:"right",color:"Highlight"}}>
          <h6>नियम व शर्तें लागू</h6>
            </div>
        </div> 
        <div>
        <marquee ><h1>आन ,मान, शान ,धन-दौलत, समाज सेवा,  सभी एक साथ आकर्षक आय के साथ स्वतंत्र व्यवसाय</h1></marquee>
        </div>
        <div style={{margin:20}} >
            
            <div className="box bg-light" style={{borderRadius:10}}>
                <h3 style={{color:"Highlight"}}>अतिरिक्त सुविधाएं</h3>
                <ol style={{fontSize:25,margin:20}}>
                    <li>बिना ब्याज की कार / मोटरसाइकिल एडवांस</li>
                    <li>स्वयं कार्यालय चलाने हेतु कार्यालय भत्ता</li>
                    <li>रियायती की ब्याज दरों पर अपना आवास बनाने हेतु एडवांस</li>
                    <li>टेलीफोन मोबाइल खर्चों की प्रतिपूर्ति</li>
                    <li>शादी मुंडन फर्नीचर कंप्यूटर एंड त्योहारों पर अग्रिम सुविधाएं</li>
                    <li>वातानुकूलित हवाई यात्रा एवं रेल यात्रा किराया</li>
                    <li>ग्रेच्युटी सुविधा</li>
                </ol>
            </div>
            <div style={{textAlign:"right",color:"Highlight"}}>
          <h6>नियम व शर्तें लागू</h6>
            </div>
        </div>
    </div>
  )
}

export default Slider;